import { API_BASE_URL, PATH_MAIN_ROUTE } from "../core/Constants";

export const URL_VIRTUAL_MACHINE_LOCATIONS: string = `${API_BASE_URL}/virtual-machine-locations/`;
export const URL_VIRTUAL_MACHINE_LOCATION: string = `${URL_VIRTUAL_MACHINE_LOCATIONS}:id/`;
export const URL_VIRTUAL_MACHINE_TEMPLATES: string = `${API_BASE_URL}/virtual-machine-templates/`;
export const URL_VIRTUAL_MACHINE_TEMPLATE: string = `${URL_VIRTUAL_MACHINE_TEMPLATES}:id/`;
export const URL_VIRTUAL_MACHINE_BACKUP_TEMPLATES: string = `${API_BASE_URL}/virtual-machine-backup-templates/`;
export const URL_VIRTUAL_MACHINE_BACKUP_TEMPLATE: string = `${URL_VIRTUAL_MACHINE_BACKUP_TEMPLATES}:id/`;
export const URL_VIRTUAL_MACHINE_IMAGES: string = `${API_BASE_URL}/virtual-machine-images/`;
export const URL_VIRTUAL_MACHINES: string = `${API_BASE_URL}/virtual-machines/`;
export const URL_VIRTUAL_MACHINE: string = `${URL_VIRTUAL_MACHINES}:id/`;
export const URL_VIRTUAL_MACHINE_BACKUPS: string = `${API_BASE_URL}/virtual-machine-backups/`;
export const URL_VIRTUAL_MACHINE_BACKUP: string = `${URL_VIRTUAL_MACHINE_BACKUPS}:id/`;
export const URL_VIRTUAL_MACHINE_BACKUP_RESTORE: string = `${URL_VIRTUAL_MACHINE_BACKUP}restore/`;

export const URL_CONTABO_VIRTUAL_MACHINES: string = `${API_BASE_URL}/contabo-virtual-machines/`;
export const URL_CONTABO_VIRTUAL_MACHINE: string = `${URL_CONTABO_VIRTUAL_MACHINES}:id/`;
export const URL_CONTABO_VIRTUAL_MACHINE_IMAGES: string = `${API_BASE_URL}/contabo-images/`;
export const URL_CONTABO_VIRTUAL_MACHINE_SNAPSHOTS: string = `${API_BASE_URL}/contabo-virtual-machine-backups/`;

export const URL_CONTABO_VIRTUAL_MACHINE_SNAPSHOT: string = `${URL_CONTABO_VIRTUAL_MACHINE_SNAPSHOTS}:id/`;
export const URL_CONTABO_VIRTUAL_MACHINE_SNAPSHOT_RESTORE: string = `${URL_CONTABO_VIRTUAL_MACHINE_SNAPSHOT}restore/`;

export const URL_CONTABO_VIRTUAL_MACHINE_CREATE: string = `${URL_CONTABO_VIRTUAL_MACHINES}`;

export const URL_CONTABO_VIRTUAL_MACHINE_RESTART: string = `${URL_CONTABO_VIRTUAL_MACHINES}:id/restart/`;
export const URL_CONTABO_VIRTUAL_MACHINE_START: string = `${URL_CONTABO_VIRTUAL_MACHINES}:id/start/`;
export const URL_CONTABO_VIRTUAL_MACHINE_STOP: string = `${URL_CONTABO_VIRTUAL_MACHINES}:id/stop/`;
export const URL_CONTABO_VIRTUAL_MACHINE_SHUTDOWN: string = `${URL_CONTABO_VIRTUAL_MACHINES}:id/shutdown/`;
export const URL_CONTABO_VIRTUAL_MACHINE_DELETE: string = `${URL_CONTABO_VIRTUAL_MACHINES}:id/delete/`;
export const URL_CONTABO_VIRTUAL_MACHINE_RESET_PASSWORD: string = `${URL_CONTABO_VIRTUAL_MACHINES}:id/reset-password/`;
export const URL_CONTABO_VIRTUAL_MACHINE_CHANGE_IMAGE: string = `${URL_CONTABO_VIRTUAL_MACHINES}:id/change-image/`;
export const URL_CONTABO_VIRTUAL_MACHINE_TAKE_SNAPSHOT: string = `${URL_CONTABO_VIRTUAL_MACHINES}:id/take-snapshot/`;
export const URL_CONTABO_VIRTUAL_MACHINE_CHANGE_DISPLAY_NAME: string = `${URL_CONTABO_VIRTUAL_MACHINES}:id/update-display-name/`;

export const URL_OTHERS_VIRTUAL_MACHINES: string = `${API_BASE_URL}/other-virtual-machines/`;
export const URL_OTHERS_VIRTUAL_MACHINE: string = `${URL_OTHERS_VIRTUAL_MACHINES}:id/`;
export const URL_OTHERS_MACHINE_DELETE: string = `${URL_OTHERS_VIRTUAL_MACHINES}:id/`;
export const URL_OTHERS_MACHINE_HIDE: string = `${URL_OTHERS_VIRTUAL_MACHINES}:id/hide/`;
export const URL_OTHERS_MACHINE_CREATE: string = `${URL_OTHERS_VIRTUAL_MACHINES}`;
export const URL_OTHERS_MACHINE_START: string = `${URL_OTHERS_VIRTUAL_MACHINE}start/`;
export const URL_OTHERS_MACHINE_STOP: string = `${URL_OTHERS_VIRTUAL_MACHINE}stop/`;
export const URL_OTHERS_MACHINE_RESTART: string = `${URL_OTHERS_VIRTUAL_MACHINE}restart/`;
export const URL_OTHERS_MACHINE_CHANGE_DISPLAY_NAME: string = `${URL_OTHERS_VIRTUAL_MACHINE}update-display-name/`;
export const URL_OTHERS_MACHINE_CHANGE_TEMPLATE: string = `${URL_OTHERS_VIRTUAL_MACHINE}change-template/`;
export const URL_OTHERS_MACHINE_RESET_PASSWORD: string = `${URL_OTHERS_VIRTUAL_MACHINE}reset-password/`;
export const URL_OTHERS_MACHINE_TAKE_BACKUP: string = `${URL_OTHERS_VIRTUAL_MACHINE}take-backup/`;

export const URL_OTHERS_MACHINE_BACKUP: string = `${API_BASE_URL}/other-virtual-machine-backups/`;
export const URL_OTHERS_MACHINE_BACKUP_RESTORE: string = `${URL_OTHERS_MACHINE_BACKUP}:id/restore/`;




export const URL_VIRTUALIZOR_VIRTUAL_MACHINE_IMAGES: string = `${API_BASE_URL}/virtualizor-images/`;

export const URL_ALL_VIRTUAL_MACHINES: string = `${URL_OTHERS_VIRTUAL_MACHINES}all-vms/`;


export const URL_VIRTUAL_MACHINE_CREATE: string = `${URL_VIRTUAL_MACHINES}`;
export const URL_VIRTUAL_MACHINE_PAUSE: string = `${URL_VIRTUAL_MACHINES}:id/pause/`;
export const URL_VIRTUAL_MACHINE_SOFT_REBOOT: string = `${URL_VIRTUAL_MACHINES}:id/soft-reboot/`;
export const URL_VIRTUAL_MACHINE_RESTART: string = `${URL_VIRTUAL_MACHINES}:id/restart/`;
export const URL_VIRTUAL_MACHINE_START: string = `${URL_VIRTUAL_MACHINES}:id/start/`;
export const URL_VIRTUAL_MACHINE_STOP: string = `${URL_VIRTUAL_MACHINES}:id/stop/`;
export const URL_VIRTUAL_MACHINE_SNAPSHOT: string = `${URL_VIRTUAL_MACHINES}:id/take-snapshot/`;
export const URL_VIRTUAL_MACHINE_TAKE_BACKUP: string = `${URL_VIRTUAL_MACHINES}:id/take-backup/`;
export const URL_VIRTUAL_MACHINE_UNPAUSE: string = `${URL_VIRTUAL_MACHINES}:id/unpause/`;
export const URL_VIRTUAL_MACHINE_DISABLE_BACKUP: string = `${URL_VIRTUAL_MACHINES}:id/disable-backup/`;
export const URL_VIRTUAL_MACHINE_CHANGE_BACKUP_TEMPLATE: string = `${URL_VIRTUAL_MACHINES}:id/change-backup-template/`;
export const URL_VIRTUAL_MACHINE_CHANGE_TEMPLATE: string = `${URL_VIRTUAL_MACHINES}:id/change-template/`;
export const URL_VIRTUAL_MACHINE_REBUILD: string = `${URL_VIRTUAL_MACHINES}:id/rebuild/`;
export const URL_VIRTUAL_MACHINE_CLONE: string = `${URL_VIRTUAL_MACHINES}:id/clone/`;
export const URL_VIRTUAL_MACHINE_DELETE: string = `${URL_VIRTUAL_MACHINES}:id/`;
export const URL_VIRTUAL_MACHINE_HIDE: string = `${URL_VIRTUAL_MACHINES}:id/hide/`;
export const URL_VIRTUAL_MACHINE_PASSWORD_RESET: string = `${URL_VIRTUAL_MACHINES}:id/reset-password/`;
export const URL_VIRTUAL_MACHINE_CHANGE_IMAGE: string = `${URL_VIRTUAL_MACHINES}:id/change-image/`;
export const URL_VIRTUAL_MACHINE_CHANGE_DISPLAY_NAME: string = `${URL_VIRTUAL_MACHINES}:id/update-display-name/`;

export const PATH_VIRTUAL_MACHINES: string = `${PATH_MAIN_ROUTE}/virtual-machines`;
export const PATH_LIST_VIRTUAL_MACHINES: string = "list";
export const FULL_PATH_LIST_VIRTUAL_MACHINES: string = `${PATH_VIRTUAL_MACHINES}/${PATH_LIST_VIRTUAL_MACHINES}`;
export const PATH_DEPLOY_VM: string = "deploy-vm";
export const PATH_MANAGE_VM: string = "manage-vm/:vm_type/:id";
export const HELP_CENTER_URL: string = "https://cloudpap.com/docs/";
