import { useParams } from "react-router-dom";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import ServersApiActions from "modules/servers/ApiActions";
import VirtualMachineActions from "modules/servers/components/VirtualMachineActions";
import VirtualMachineCard from "modules/servers/components/VirtualMachineCard";

/**
 * Virtual Machine Deploy
 * @returns {JSX.Element}
 * @constructor
 */
function VirtualMachineDetails() {
  const { id: vm_id, vm_type } = useParams();

  const serversApiActions = new ServersApiActions();
  const { vm, error, isLoading } = serversApiActions.getVirtualMachine(
    vm_id,
    vm_type,
  );
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <VirtualMachineCard
        key={vm.id}
        virtual_machine={vm}
        vm_type={vm_type}
        actions={
          <VirtualMachineActions
            subject_id={vm.id}
            row={vm}
            platform={vm_type}
          />
        }
        allowIPCopy
        isClickable={false}
      />
    </>
  );
}

export default VirtualMachineDetails;
