import { createColumnHelper } from "@tanstack/react-table";
import { Modal as BootstrapModal } from "bootstrap";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import ActionInput from "modules/base/components/ActionInput";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SectionCard from "modules/base/components/SectionCard";
import DataTable from "modules/base/components/table/Table";
import { formatDate } from "modules/base/utilities/Actions";
import ServersApiActions from "modules/servers/ApiActions";
import BackupSubscription from "modules/servers/components/BackupSubscription";

/**
 * Component for displaying and managing virtual machine backups.
 * @returns {JSX.Element} The rendered component.
 */
function VirtualMachineBackups() {
  const { id: vm_id, vm_type } = useParams();
  const serversApiActions = new ServersApiActions();
  const { backups, isLoading, error } =
    serversApiActions.getVirtualMachineBackups(vm_id, vm_type);
  const columnHelper = createColumnHelper();

  /**
   * Formats a boolean value as a corresponding icon element.
   * @param {boolean} value - The boolean value to format.
   * @returns {JSX.Element} The formatted icon element.
   */
  const formatBoolean = (value) => {
    return value ? (
      <i className="bi bi-check-circle-fill text-success" />
    ) : (
      <i className="bi bi-x-circle-fill text-danger" />
    );
  };

  /**
   * Button to restore a virtual machine backup if it is ready to use.
   * @param {Object} row - Contain backup information.
   * @returns {JSX.Element}
   */
  const restoreBackupButton = (row) => {
    const { id: value, ready_to_use } = row;
    const onclickHandler = useCallback(
      (id, successCallback, errorCallback) => {
        serversApiActions.restoreVirtualMachineBackup(
          value,
          vm_type,
          successCallback,
          (error_response) =>
            errorCallback("Error restoring backup", error_response),
        );
      },
      [value],
    );
    if (!ready_to_use) {
      return <div />;
    }
    return (
      <ActionInput
        label="Restore"
        icon="arrow-repeat"
        onClick={onclickHandler}
        showConfirm
        confirm={{
          title: "Restore this backup?",
          message:
            "This will restore the backup and overwrite the current state of the virtual machine. This action cannot be undone.",
          confirm_button_label: "Restore",
          action_done_title: "Backup Restoring",
          action_done_message: "The backup is being restored.",
          confirm_button_color: "danger",
        }}
        subject_id={value}
        button_classes="btn btn-purple btn-sm"
      />
    );
  };
  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("created_at", {
      header: "Created At",
      cell: (info) => formatDate(info.renderValue(), "llll"),
    }),
    columnHelper.accessor("ready_to_use", {
      header: "Ready To Use",
      cell: (info) => formatBoolean(info.renderValue()),
    }),
    columnHelper.accessor("id", {
      header: "",
      cell: (info) => restoreBackupButton(info.row.original),
    }),
  ];
  const handleChangeFrequency = useCallback(() => {
    const changeFrequencyModal = BootstrapModal.getOrCreateInstance(
      document.getElementById("change-backup-frequency"),
    );
    changeFrequencyModal.show();
  }, []);
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <BackupSubscription vm_id={vm_id} vm_type={vm_type} />
      <SectionCard
        title={
          <>
            Backups{" "}
            <button
              type="button"
              className="btn btn-purple btn-sm"
              onClick={handleChangeFrequency}
            >
              <i className="bi bi-wrench-adjustable" /> Change frequency
            </button>
          </>
        }
      >
        <DataTable columns={columns} data={backups} isLoading={false} />
      </SectionCard>
    </>
  );
}

export default VirtualMachineBackups;
