import PropTypes from "prop-types";
import SectionCard from "modules/base/components/SectionCard";

/**
 * Teams info card component
 * @returns {JSX.Element}
 * @constructor
 */
function TeamDescription(props) {
  const { team } = props;

  return (
    <SectionCard title={team.name}>
      <p className="card-text text-muted">{team.description}</p>
    </SectionCard>
  );
}

TeamDescription.propTypes = {
  team: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

export default TeamDescription;
