import { createColumnHelper } from "@tanstack/react-table";
import PropTypes from "prop-types";
import { useCallback } from "react";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";

/**
 * Team Members list card component
 * @returns {JSX.Element}
 * @constructor
 */
function TeamMembersList(props) {
  const { members, isLoading } = props;
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("full_name", {
      header: null,
      cell: (info) => info.renderValue(),
    }),

    columnHelper.accessor("email", {
      header: null,
      cell: (info) => info.renderValue(),
    }),
  ];

  const handleOpenModal = useCallback(() => {
    openModal("invite-member-modal");
  }, [openModal]);

  return (
    <DataTable
      enableRowSelection
      enableMultiRowSelection
      allowCreate
      enableSearch
      onClickCreate={handleOpenModal}
      columns={columns}
      data={members}
      isLoading={isLoading}
      createButtonColor="purple"
      create_button_label="Invite"
    />
  );
}

TeamMembersList.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      full_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool,
};

TeamMembersList.defaultProps = {
  isLoading: false,
};
export default TeamMembersList;
