import { useCallback } from "react";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SectionCard from "modules/base/components/SectionCard";
import DataTable from "modules/base/components/table/Table";
import { openModal } from "modules/base/utilities/Actions";
import TeamsApiActions from "modules/teams/ApiActions";
import CreateTeamForm from "modules/teams/components/CreateTeamForm";
import { teamsColumns } from "modules/teams/components/TeamsColumns";

/**
 * Teams List display card component
 * @returns {JSX.Element}
 * @constructor
 */
function TeamsList() {
  const teamsApiActions = new TeamsApiActions();

  const { teams, isLoading, error } = teamsApiActions.getTeams();

  const handleOpenModal = useCallback(() => {
    openModal("team-create-modal");
  }, [openModal]);

  if (error) {
    return <NetworkMessageDisplay error={error} />;
  }

  return (
    <SectionCard title="Teams">
      <CreateTeamForm />
      <DataTable
        columns={teamsColumns}
        data={teams}
        enableSearch={Boolean(teams?.length)}
        enableRowSelection={Boolean(teams?.length)}
        enableMultiRowSelection={Boolean(teams?.length)}
        allowCreate
        create_button_label="New Team"
        onClickCreate={handleOpenModal}
        createButtonColor="purple"
        isLoading={isLoading}
      />
    </SectionCard>
  );
}

export default TeamsList;
