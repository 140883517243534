import { API_BASE_URL, PATH_MAIN_ROUTE } from "../core/Constants";

export const URL_TEAMS: string = `${API_BASE_URL}/teams/`;
export const URL_TEAM: string = `${URL_TEAMS}:id/`;
export const URL_TEAM_INVITE: string = `${URL_TEAM}invite-user/`;
export const URL_TEAM_INVITES_LIST: string = `${URL_TEAM}invites/`;

export const PATH_TEAMS: string = `${PATH_MAIN_ROUTE}/teams`;
export const PATH_TEAMS_LIST: string = "list";
export const PATH_MANAGE_TEAM: string = "manage-team/:id";

export const URL_TENANTS: string = `${API_BASE_URL}/tenants/`;
export const URL_CREATE_TEAM: string = `${URL_TENANTS}i/create-team/`;
