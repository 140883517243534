import { BaseModel } from "modules/base/models/Base";

/**
 * Team Member Model
 */
export class TeamMember extends BaseModel {
  id: number;

  email: string;

  full_name: string;

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.email = data.email;
    this.full_name = data.full_name;
  }
}

/**
 * Team Model
 */
export class Team extends BaseModel {
  id: number;

  name: string;

  description: string;

  tenant_name: string;

  members: TeamMember[];

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.tenant_name = data.tenant_name;
    const teamMember = new TeamMember();
    this.members = teamMember.fromArray(data.members);
  }
}

/**
 * User TeamInvite Model
 */
export class TeamInvite extends BaseModel {
  id: number;

  team_name: string;

  tenant_name: string;

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.team_name = data.team?.name ?? null;
    this.tenant_name = data.team?.tenant_name ?? null;
  }
}
