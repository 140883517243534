import PropTypes from "prop-types";
import ServersApiActions from "../ApiActions";
import CorePlanSelection from "modules/core/components/CorePlanSelection";

/**
 * Plan selection component
 * @returns {JSX.Element}
 * @constructor
 */
function PlanSelection(props) {
  const serversApiActions = new ServersApiActions();
  const {
    onSelect,
    selected_location,
    no_of_md_cols,
    selected_plan_id,
    showConfirm,
    confirm,
    minimum_template_id,
  } = props;
  const location_id = selected_location?.id;
  const { templates, error, isLoading } =
    serversApiActions.getVirtualMachineTemplates(location_id);
  return (
    <CorePlanSelection
      onSelect={onSelect}
      error={error}
      isLoading={isLoading}
      templates={templates}
      no_of_md_cols={no_of_md_cols}
      selected_plan_id={selected_plan_id}
      showConfirm={showConfirm}
      confirm={confirm}
      minimum_template_id={minimum_template_id}
    />
  );
}

PlanSelection.defaultProps = {
  selected_location: null,
  no_of_md_cols: 4,
  selected_plan_id: null,
  showConfirm: false,
  confirm: {
    title: "Are you sure?",
    message: "You won't be able to revert this!",
    confirm_button_label: "Yes, do it!",
    action_done_title: "Done",
    action_done_message: "Your request has been processed successfully",
    confirm_button_color: "danger",
  },
  minimum_template_id: null,
};

PlanSelection.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected_location: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  no_of_md_cols: PropTypes.number,
  selected_plan_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showConfirm: PropTypes.bool,
  confirm: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirm_button_label: PropTypes.string.isRequired,
    action_done_title: PropTypes.string.isRequired,
    action_done_message: PropTypes.string.isRequired,
    confirm_button_color: PropTypes.string,
  }),
  minimum_template_id: PropTypes.number,
};

export default PlanSelection;
