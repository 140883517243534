import ContentContainer from "../../core/layouts/ContentContainer";
import MainContainer from "../../core/layouts/MainContainer";
import SideBar from "../../core/layouts/SideBar";
import VirtualMachinesList from "../components/VirtualMachinesList";
import TopBar from "modules/core/layouts/TopBar";

/**
 * List of Virtual Machines Page
 * @returns {JSX.Element}
 * @constructor
 */
function ListVirtualMachines() {
  return (
    <>
      <TopBar />
      <MainContainer>
        <SideBar />
        <ContentContainer cols={10} mobile_cols={10}>
          <VirtualMachinesList />
        </ContentContainer>
      </MainContainer>
    </>
  );
}

export default ListVirtualMachines;
