import { Modal as BootstrapModal } from "bootstrap";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import Form from "modules/base/components/Form";
import TextInput from "modules/base/components/inputs/TextInput";
import Modal from "modules/base/components/Modal";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import ServersApiActions from "modules/servers/ApiActions";

/**
 * Virtual Machine Display Name Form
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function VirtualMachineDisplayNameForm({ vmDisplayName, vmId, platform }) {
  const serversApiActions = new ServersApiActions();
  const [displayName, setDisplayName] = useState(vmDisplayName);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const changeNameModal = BootstrapModal.getOrCreateInstance(
      document.getElementById(`change-name-${vmId}`),
    );
    changeNameModal.show();
  }, [vmId]);

  const handleCustomNameInputChange = useCallback((e) => {
    setDisplayName(e.target.value);
  }, []);

  const handleNameSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (displayName.trim() === "") {
        return true;
      }

      setSuccess(null);
      setError(null);

      serversApiActions.changeVirtualMachineName(
        vmId,
        displayName,
        platform,
        () => {
          setSuccess(`The VM has been renamed to ${displayName}`);
          setError(null);
        },
        () => {
          setError({
            message:
              "Something went wrong while renaming the app. Please try again.",
          });
          setSuccess(null);
        },
      );

      return null;
    },
    [displayName, vmId],
  );

  return (
    <Modal
      modal_title="Change name"
      modal_id={`change-name-${vmId}`}
      size="lg"
      isDismissible
    >
      <Form handleSubmit={handleNameSubmit} items_flow="vertical" padding="p-1">
        <div className="mb-4">
          <TextInput
            inputType="text"
            className="form-control m-4"
            id="displayNameInput"
            content={displayName}
            controlFunc={handleCustomNameInputChange}
            placeholder="Enter new VM display name"
          />
        </div>
      </Form>
      <NetworkMessageDisplay error={error} success={success} />
    </Modal>
  );
}

VirtualMachineDisplayNameForm.propTypes = {
  vmDisplayName: PropTypes.string.isRequired,
  vmId: PropTypes.number.isRequired,
  platform: PropTypes.string.isRequired,
};

export default VirtualMachineDisplayNameForm;
