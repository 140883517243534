import { serverGeneratePath } from "modules/base/utilities/Navigation";
import PrivateApi from "modules/core/utilities/PrivateApi";
import {
  URL_CREATE_TEAM,
  URL_TEAM,
  URL_TEAMS,
  URL_TEAM_INVITE,
  URL_TEAM_INVITES_LIST,
} from "modules/teams/Constants";
import { Team, TeamInvite } from "modules/teams/Models";

/**
 * @class
 * @name TeamsApiActions
 * @description
 * This class is used to make API calls to the teams API.
 */
export default class TeamsApiActions extends PrivateApi {
  /**
   * @function
   * @name TeamsApiActions#getTeams
   * @description
   * Get the list of teams.
   * @returns {teams: teams, error: Error, isLoading: boolean}
   */
  getTeams() {
    const { data, error, isLoading } = this.getAPIRequest(URL_TEAMS);
    const team = new Team();
    const teams = team.fromArray(data);
    return { teams, error, isLoading };
  }

  /**
   * @function
   * @name TeamsApiActions#getTeam
   * @description
   * Get details of the team
   * @returns {team: teams, error: Error, isLoading: boolean}
   */
  getTeam(id) {
    const url = serverGeneratePath(URL_TEAM, { id });
    const { data, error, isLoading } = this.getAPIRequest(url);
    const team = new Team(data);
    return { team, error, isLoading };
  }

  /**
   * @function
   * @name TeamsApiActions#getTeamInvites
   * @description
   * Get the list of team Invites.
   * @returns {teamInvites: teamInvites, error: Error, isLoading: boolean}
   */
  getTeamInvites(id) {
    const { data, error, isLoading } = this.getAPIRequest(
      serverGeneratePath(URL_TEAM_INVITES_LIST, { id })
    );
    const teamInvite = new TeamInvite();
    const teamInvites = teamInvite.fromArray(data);
    return { teamInvites, error, isLoading };
  }

  /**
   * @function
   * @name TeamsApiActions#createTeam
   * @description
   * Create a new team.
   * @param {Object} payload - The team data to create.
   * @param {string} payload.name - The name of the team.
   * @param {string} payload.description - The description of the team.
   * @param {Function} successCallback - The callback to execute on success.
   * @param {Function} errorCallback - The callback to execute on error.
   */
  createTeam(
    payload,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    this.postAPIRequest(
      URL_CREATE_TEAM,
      payload,
      successCallback,
      errorCallback,
      "POST",
      [URL_TEAMS]
    );
  }

  /**
   * @function
   * @name TeamsApiActions#inviteUser
   * @description
   * Invite a user to join the team.
   * @param {number} teamId - The ID of the team to invite the user to.
   * @param {string} email - The email address of the user to invite.
   * @param {Function} successCallback - The callback to execute on success.
   * @param {Function} errorCallback - The callback to execute on error.
   */
  inviteUser(
    teamId,
    email,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_TEAM_INVITE, { id: teamId });
    const payload = { email };
    this.postAPIRequest(url, payload, successCallback, errorCallback, "POST", [
      url,
    ]);
  }
}
