import PropTypes from "prop-types";
import { serverGeneratePath } from "modules/base/utilities/Navigation";
import ResourceCard from "modules/core/components/ResourceCard";
import {
  PATH_MANAGE_VM,
  PATH_VIRTUAL_MACHINES,
} from "modules/servers/Constants";
import "react-toastify/dist/ReactToastify.css";
import { VirtualMachine } from "modules/servers/Models";

/**
 * Virtual Machine Card
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function VirtualMachineCard(props) {
  const { virtual_machine, actions, allowIPCopy, isClickable, vm_type } = props;
  const manage_vm_path = serverGeneratePath(
    `${PATH_VIRTUAL_MACHINES}/${PATH_MANAGE_VM}`,
    {
      id: virtual_machine.id,
      vm_type: virtual_machine.type,
    },
  );
  return (
    <ResourceCard
      actions={actions}
      resource={virtual_machine}
      manage_path={manage_vm_path}
      allowIPCopy={allowIPCopy}
      isClickable={isClickable}
      resource_type="vm"
      platform={vm_type}
    />
  );
}

VirtualMachineCard.defaultProps = {
  allowIPCopy: false,
  isClickable: true,
};

VirtualMachineCard.propTypes = {
  virtual_machine: PropTypes.instanceOf(VirtualMachine).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  vm_type: PropTypes.string.isRequired,
  allowIPCopy: PropTypes.bool,
  isClickable: PropTypes.bool,
};

export default VirtualMachineCard;
