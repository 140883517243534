import { useCallback, useState } from "react";
import Form from "modules/base/components/Form";
import TextInput from "modules/base/components/inputs/TextInput";
import Modal from "modules/base/components/Modal";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import TeamApiActions from "modules/teams/ApiActions";

/**
 * Create Team component
 * @returns {JSX.Element}
 * @constructor
 */
function CreateTeamForm() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const teamApiActions = new TeamApiActions();
  const [state, setState] = useState({
    name: "",
    description: "",
  });
  const { name, description } = state;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      teamApiActions.createTeam(
        state,
        () => {
          setSuccess(`${name} has been created.`);
          setError(null);
          setState({
            name: "",
            description: "",
          });
          setIsSubmitting(false);
        },
        (createError) => {
          setError(createError);
          setSuccess(null);
          setIsSubmitting(false);
        },
      );
    },
    [state],
  );

  const handleInputChange = useCallback((e) => {
    const { name: field_name, value: field_value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [field_name]: field_value,
    }));
  });

  return (
    <Modal
      modal_id="team-create-modal"
      isDismissible
      modal_title="Create Team"
      size="md"
    >
      <NetworkMessageDisplay error={error} success={success} />
      <Form
        handleSubmit={handleSubmit}
        button_label="Create"
        id="create-team-form"
        padding="p-0"
        isSubmitting={isSubmitting}
      >
        <div className="row row-cols-1 mb-2">
          <TextInput
            title="Name"
            name="name"
            content={name}
            controlFunc={handleInputChange}
            required
          />
        </div>
        <div className="row row-cols-1 mb-2">
          <TextInput
            title="Description"
            name="description"
            content={description}
            controlFunc={handleInputChange}
          />
        </div>
      </Form>
    </Modal>
  );
}

export default CreateTeamForm;
