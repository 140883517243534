import PropTypes from "prop-types";
import React from "react";
import { InstalledApp } from "modules/apps/Models";
import { serverGeneratePath } from "modules/base/utilities/Navigation";
import ResourceCard from "modules/core/components/ResourceCard";
import {
  PATH_MANAGE_VM,
  PATH_VIRTUAL_MACHINES,
} from "modules/servers/Constants";
import "react-toastify/dist/ReactToastify.css";

/**
 * Virtual Machine Card
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function InstalledAppCard(props) {
  const { installed_app, actions, allowIPCopy, isClickable } = props;
  const manage_vm_path = serverGeneratePath(
    `${PATH_VIRTUAL_MACHINES}/${PATH_MANAGE_VM}`,
    {
      id: installed_app.id,
      vm_type: installed_app.vm_type,
    },
  );
  return (
    <ResourceCard
      actions={actions}
      resource={installed_app}
      manage_path={manage_vm_path}
      allowIPCopy={allowIPCopy}
      isClickable={isClickable}
      resource_type="app"
    />
  );
}

InstalledAppCard.defaultProps = {
  allowIPCopy: false,
  isClickable: true,
};

InstalledAppCard.propTypes = {
  installed_app: PropTypes.instanceOf(InstalledApp).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  allowIPCopy: PropTypes.bool,
  isClickable: PropTypes.bool,
};

export default InstalledAppCard;
