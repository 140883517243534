import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import Form from "modules/base/components/Form";
import EmailInput from "modules/base/components/inputs/EmailInput";
import Modal from "modules/base/components/Modal";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import TeamApiActions from "modules/teams/ApiActions";

/**
 * Invite user component
 * @returns {JSX.Element}
 * @constructor
 */

function InviteMemberForm(props) {
  const { teamId } = props;
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const teamApiActions = new TeamApiActions();
  const [email, setEmail] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      teamApiActions.inviteUser(
        teamId,
        email,
        () => {
          setSuccess(`An invitation has been sent to ${email}.`);
          setError(null);
          setEmail("");
          setIsSubmitting(false);
        },
        (createError) => {
          setError(createError);
          setSuccess(null);
          setIsSubmitting(false);
        },
      );
    },
    [email, teamId],
  );

  const handleInputChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  return (
    <Modal
      modal_id="invite-member-modal"
      isDismissible
      modal_title="Invite member"
      size="md"
    >
      <NetworkMessageDisplay error={error} success={success} />
      <Form
        handleSubmit={handleSubmit}
        button_label="Invite"
        id="invite-member-form"
        padding="p-0"
        isSubmitting={isSubmitting}
      >
        <div className="row row-cols-1 mb-2">
          <EmailInput
            title="Email"
            name="email"
            content={email}
            controlFunc={handleInputChange}
            required
          />
        </div>
      </Form>
    </Modal>
  );
}

InviteMemberForm.propTypes = {
  teamId: PropTypes.number.isRequired,
};

export default InviteMemberForm;
