import { Modal } from "bootstrap";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServersApiActions from "../ApiActions";
import VncViewer from "./VncViewer";
import ActionsDropdown from "modules/base/components/ActionsDropdown";
import VirtualMachineDisplayNameForm from "modules/servers/components/VirtualMachineDisplayNameForm";
import {
  PATH_LIST_VIRTUAL_MACHINES,
  PATH_VIRTUAL_MACHINES,
} from "modules/servers/Constants";

/**
 * Component for rendering actions related to a virtual machine.
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered component.
 */
function VirtualMachineActions(props) {
  const serversApiActions = new ServersApiActions();
  const [vncViewer, setVncViewer] = useState(null);
  const [showCustomNameModal, setShowCustomNameModal] = useState(false);
  const { subject_id, row, platform } = props;
  const navigate = useNavigate();
  const { raw_state: state, name, vnc_auth_token, vnc_url, display_name } = row;

  const start_stop_action = {
    label: "Stop",
    icon: "stop-circle",
    onClick: (id, successCallback, errorCallback) => {
      serversApiActions.stopVirtualMachine(
        id,
        platform,
        successCallback,
        (error) => errorCallback("Error stopping virtual machine", error),
      );
    },
    showConfirm: true,
    confirm: {
      title: "Stop this virtual machine?",
      message:
        "The virtual machine will be stopped and will not be accessible until it is started.",
      confirm_button_label: "Stop",
      action_done_title: "Stopped",
      action_done_message: "The virtual machine has been stopped.",
    },
  };

  const pause_resume_action = {
    label: "Pause",
    icon: "pause",
    onClick: (id, successCallback, errorCallback) => {
      serversApiActions.pauseVirtualMachine(id, successCallback, (error) =>
        errorCallback("Error pausing virtual machine", error),
      );
    },
    showConfirm: true,
    confirm: {
      title: "Pause this virtual machine?",
      message:
        "The virtual machine will be paused and will not be accessible until it is resumed.",
      confirm_button_label: "Pause",
      action_done_title: "Paused",
      action_done_message: "The virtual machine has been paused.",
    },
  };
  const take_snapshot = {
    label: "Take snapshot",
    icon: "camera",
    showConfirm: true,
    onClick: (id, successCallback, errorCallback) => {
      serversApiActions.snapshotVirtualMachine(
        id,
        platform,
        successCallback,
        (error) => errorCallback("Error taking snapshot", error),
      );
    },
    confirm: {
      title: "Take snapshot of this virtual machine?",
      message:
        "The current state of the virtual machine will be saved as a snapshot.",
      confirm_button_label: "Take snapshot",
      action_done_title: "Snapshot taken",
      action_done_message: "The snapshot has been taken.",
      confirm_button_color: "success",
    },
  };

  const vm_backup = {
    label: "Backup",
    icon: "cloud-check",
    showConfirm: true,
    onClick: (id, successCallback, errorCallback) => {
      serversApiActions.backupVirtualMachine(
        id,
        platform,
        successCallback,
        (error) => errorCallback("Error taking backup", error.message),
      );
    },
    confirm: {
      title: "Backup this virtual machine?",
      message:
        "The current state of the virtual machine will be saved as a backup.",
      confirm_button_label: "Backup",
      action_done_title: "Backup taken",
      action_done_message: "The backup has been taken.",
      confirm_button_color: "success",
    },
  };

  if (state === "paused") {
    pause_resume_action.label = "Unpause";
    pause_resume_action.icon = "play";
    pause_resume_action.onClick = (id, successCallback, errorCallback) => {
      serversApiActions.unPauseVirtualMachine(id, successCallback, (error) =>
        errorCallback("Error resuming virtual machine", error),
      );
    };
    pause_resume_action.confirm = {
      title: "Unpause this virtual machine?",
      message:
        "The virtual machine will be resumed and will be accessible after it has booted.",
      confirm_button_label: "Unpause",
      action_done_title: "Resumed",
      action_done_message: "The virtual machine has been resumed.",
      confirm_button_color: "success",
    };
  }

  if (state === "stopped") {
    start_stop_action.label = "Start";
    start_stop_action.icon = "power";
    start_stop_action.onClick = (id, successCallback, errorCallback) => {
      serversApiActions.startVirtualMachine(
        id,
        platform,
        successCallback,
        (error) => errorCallback("Error starting virtual machine", error),
      );
    };
    start_stop_action.confirm = {
      title: "Start this virtual machine?",
      message:
        "The virtual machine will be started and will be accessible after it has booted.",
      confirm_button_label: "Start",
      action_done_title: "Started",
      action_done_message: "The virtual machine has been started.",
      confirm_button_color: "success",
    };
  }

  const soft_reboot_action = {
    label: "Soft Reboot",
    icon: "arrow-repeat",
    onClick: (id, successCallback, errorCallback) => {
      serversApiActions.softRebootVirtualMachine(id, successCallback, (error) =>
        errorCallback("Error rebooting virtual machine", error),
      );
    },
    showConfirm: true,
    confirm: {
      title: "Reboot this virtual machine?",
      message:
        "The virtual machine will be temporarily unavailable while it is rebooting.",
      confirm_button_label: "Reboot",
      action_done_title: "Rebooted",
      action_done_message: "The virtual machine has been rebooted.",
    },
  };

  const restart_action = {
    label: "Hard Reboot",
    icon: "arrow-repeat",
    onClick: (id, successCallback, errorCallback) => {
      serversApiActions.restartVirtualMachine(
        id,
        platform,
        successCallback,
        (error) => errorCallback("Error rebooting virtual machine", error),
      );
    },
    showConfirm: true,
    confirm: soft_reboot_action.confirm,
  };

  /**
   * Launches a VNC viewer for the specified virtual machine.
   * @param {string} vm_name - The name of the virtual machine.
   */
  const handleLaunchVNC = (vm_name) => {
    setVncViewer(
      <VncViewer
        vm_name={vm_name}
        vnc_auth_token={vnc_auth_token}
        vnc_url={vnc_url}
      />,
    );
  };

  useEffect(() => {
    if (vncViewer) {
      const vncViewerModal = new Modal(
        document.getElementById(`vnc-viewer-modal-${name}`),
      );
      vncViewerModal.toggle();
    }
  }, [vncViewer, name]);

  const base_actions_list = [
    {
      label: "Rename",
      icon: "pencil",
      onClick: () => setShowCustomNameModal(true),
      showConfirm: false,
    },
    start_stop_action,
    restart_action,
    {
      label: "Reset password",
      icon: "key",
      showConfirm: true,
      onClick: (id, successCallback, errorCallback) => {
        serversApiActions.resetVirtualMachinePassword(
          id,
          platform,
          successCallback,
          (error) => errorCallback("Error resetting password", error),
        );
      },
      confirm: {
        title: "Reset password for this virtual machine?",
        message:
          "A new password will be generated and sent to the email address on file.",
        confirm_button_label: "Reset password",
        action_done_title: "Password reset",
        action_done_message: "The password has been reset.",
      },
    },
  ];

  const delete_vm = {
    label: "Delete",
    icon: "trash",
    onClick: (id, successCallback, errorCallback) => {
      serversApiActions.deleteVirtualMachine(
        id,
        platform,
        () => {
          navigate(`${PATH_VIRTUAL_MACHINES}/${PATH_LIST_VIRTUAL_MACHINES}`);
          successCallback();
        },
        (error) => errorCallback("Error deleting virtual machine", error),
      );
    },
    showConfirm: true,
    confirm: {
      title: "Delete this virtual machine?",
      message:
        "This action cannot be undone. All data will be permanently deleted.",
      confirm_button_label: "Delete",
      action_done_title: "Deleted",
      action_done_message: "The virtual machine has been deleted.",
    },
  };

  const remove_vm = {
    label: "Remove",
    icon: "x-lg",
    onClick: (id, successCallback, errorCallback) => {
      serversApiActions.removeVirtualMachine(
        id,
        platform,
        () => {
          navigate(`${PATH_VIRTUAL_MACHINES}/${PATH_LIST_VIRTUAL_MACHINES}`);
          successCallback();
        },
        (error) => errorCallback("Error deleting virtual machine", error),
      );
    },
    showConfirm: true,
    confirm: {
      title: "Remove this virtual machine from your dashboard?",
      message: "This action cannot be undone. It will not show up again.",
      confirm_button_label: "Remove",
      action_done_title: "Virtual Machine Removed",
      action_done_message: "The card has been removed.",
    },
  };

  const hci_addition_actions_list = [
    {
      label: "Open VNC",
      icon: "display",
      onClick: () => handleLaunchVNC(name),
      showConfirm: false,
    },
    pause_resume_action,
    soft_reboot_action,

    {
      label: "Rebuild",
      icon: "hammer",
      showConfirm: true,
      onClick: (id, successCallback, errorCallback) => {
        serversApiActions.rebuildVirtualMachine(
          id,
          () => {
            navigate(`${PATH_VIRTUAL_MACHINES}/${PATH_LIST_VIRTUAL_MACHINES}`);
            successCallback();
          },
          (error) => errorCallback("Error rebuilding virtual machine", error),
        );
      },
      confirm: {
        title: "Rebuild this virtual machine?",
        message:
          "This will destroy all data on the virtual machine and create a new virtual machine.",
        confirm_button_label: "Rebuild",
        action_done_title: "Rebuilding",
        action_done_message: "The virtual machine is being rebuilt.",
      },
    },
  ];

  let actions_list = base_actions_list;
  if (platform === "hci") {
    actions_list = [
      ...actions_list,
      ...hci_addition_actions_list,
      vm_backup,
      take_snapshot,
    ];

    if (state !== "deleted") {
      actions_list = [...actions_list, delete_vm];
    }
  } else if (platform === "vrt") {
    actions_list = [...actions_list, vm_backup];

    if (state !== "deleted") {
      actions_list = [...actions_list, delete_vm];
    }
  } else if (platform === "ctb") {
    actions_list = [...actions_list, take_snapshot];
  }

  if (state === "deleted") {
    actions_list = [remove_vm];
  }
  return (
    <>
      {vncViewer}
      {showCustomNameModal && (
        <VirtualMachineDisplayNameForm
          vmDisplayName={display_name || name}
          vmId={subject_id}
          platform={platform}
        />
      )}
      <ActionsDropdown
        label="Actions"
        actionsList={actions_list}
        subject_id={subject_id}
        platform={platform}
      />
    </>
  );
}

VirtualMachineActions.propTypes = {
  subject_id: PropTypes.number.isRequired,
  platform: PropTypes.string.isRequired,
  row: PropTypes.shape({
    raw_state: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    display_name: PropTypes.string,
    vnc_auth_token: PropTypes.string,
    vnc_url: PropTypes.string,
  }).isRequired,
};

export default VirtualMachineActions;
