import React, { useCallback, useEffect } from "react";
import useScreenType from "react-screentype-hook";
import { ReactComponent as Logo } from "../assets/cloudpap-logo.svg";
import {
  PATH_APPS,
  RELATIVE_PATH_LIST_AVAILABLE_APPS,
  RELATIVE_PATH_LIST_INSTALLED_APPS,
} from "modules/apps/Contants";
import { PATH_BILLING, PATH_VIEW_BILLING } from "modules/billing/Contants";
import SidebarItems from "modules/core/components/SidebarItems";
import { PATH_FEATURE_REQUEST, PATH_FEEDBACK } from "modules/core/Constants";
import FocusItems from "modules/marketing/components/FocusItems";
import {
  HELP_CENTER_URL,
  PATH_DEPLOY_VM,
  PATH_LIST_VIRTUAL_MACHINES,
  PATH_VIRTUAL_MACHINES,
} from "modules/servers/Constants";
import { PATH_TEAMS, PATH_TEAMS_LIST } from "modules/teams/Constants";

/**
 * SideBar component
 * @returns {JSX.Element}
 * @constructor
 */
function SideBar() {
  const screenType = useScreenType();
  const { isMobile } = screenType;
  const [is_open, setIsOpen] = React.useState(!isMobile);
  useEffect(() => {
    setIsOpen(!isMobile);
  }, [isMobile]);
  const main_items = [
    {
      name: "Compute (VM)",
      icon: "bi bi-server",
      path: PATH_LIST_VIRTUAL_MACHINES,
      base_path: PATH_VIRTUAL_MACHINES,
      active_paths: [PATH_LIST_VIRTUAL_MACHINES, PATH_DEPLOY_VM],
    },
    {
      name: "Managed Apps",
      icon: "bi bi-stack",
      active: false,
      path: RELATIVE_PATH_LIST_INSTALLED_APPS,
      base_path: PATH_APPS,
      active_paths: [
        RELATIVE_PATH_LIST_INSTALLED_APPS,
        RELATIVE_PATH_LIST_AVAILABLE_APPS,
      ],
    },
    {
      name: "Team",
      icon: "bi bi-people",
      active: false,
      path: PATH_TEAMS_LIST,
      base_path: PATH_TEAMS,
      active_paths: [PATH_TEAMS_LIST],
    },
    {
      name: "Billing",
      icon: "bi bi-receipt",
      active: false,
      path: PATH_VIEW_BILLING,
      base_path: PATH_BILLING,
      active_paths: [PATH_VIEW_BILLING],
    },
    {
      name: "Feature Requests",
      icon: "bi bi-lightbulb",
      active: false,
      path: PATH_FEATURE_REQUEST,
      base_path: PATH_FEEDBACK,
      active_paths: [PATH_FEATURE_REQUEST],
    },
  ];
  const footer_items = [
    {
      name: "Help center",
      icon: "bi bi-question",
      active: false,
      path: HELP_CENTER_URL,
      isExternal: true,
    },
    {
      name: "Service status",
      icon: "bi bi-activity",
      active: false,
      path: "",
    },
  ];
  const is_mobile_sidebar_open = isMobile && is_open;
  const logo_display = is_open ? "d-block" : "d-none";
  const sidebar_toggle_display = is_open ? "d-none" : "d-block";
  const sidebar_width = is_mobile_sidebar_open ? "w-60" : "w-16";
  const handleSetIsOpen = useCallback(() => {
    setIsOpen(!is_open);
  });
  const sidebar_backdrop = (
    <div
      className="position-fixed min-vh-100 min-vw-100"
      onClick={handleSetIsOpen}
      aria-hidden="true"
    />
  );
  const sidebar_padding = is_open ? "p-3" : "p-1";
  return (
    <>
      <FocusItems />
      {is_mobile_sidebar_open && sidebar_backdrop}
      <div
        className={`d-flex flex-nowrap vh-100 fixed-top ${sidebar_width} bg-white border-end border-black shadow-sm`}
      >
        <div
          className={`d-flex flex-column flex-shrink-0 text-bg-white w-100 ${sidebar_padding} bg-white border-end border-black shadow-sm`}
        >
          <button
            type="button"
            className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-bg-white text-decoration-none border-0 w-100 bg-white"
          >
            <Logo
              className={`${logo_display}`}
              fill="currentColor"
              width="100%"
              height="100"
            />
          </button>
          <button
            type="button"
            className={`btn btn-outline-black border-0 ${sidebar_toggle_display}`}
            onClick={handleSetIsOpen}
          >
            <i className="bi bi-list" />
          </button>
          <hr />
          <SidebarItems
            main_items={main_items}
            footer_items={footer_items}
            is_open={is_open}
          />
        </div>
      </div>
    </>
  );
}

export default SideBar;
