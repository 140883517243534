import { createColumnHelper } from "@tanstack/react-table";
import NavigationLink from "modules/base/components/NavigationLink";
import { serverGeneratePath } from "modules/base/utilities/Navigation";
import { PATH_MANAGE_TEAM, PATH_TEAMS } from "modules/teams/Constants";

const columnHelper = createColumnHelper();

/**
 * Formats the display of a team with its name and description.
 *
 * @param {Object} team - The team object to format.
 * @param {string} team.name - The name of the team.
 * @param {string} team.description - The description of the team.
 * @returns {JSX.Element} A JSX element displaying the team's name and description.
 */
function teamDisplayFormatter(team) {
  const manage_team_path = serverGeneratePath(
    `${PATH_TEAMS}/${PATH_MANAGE_TEAM}`,
    {
      id: team.id,
    }
  );
  return (
    <div className="d-flex flex-column">
      <NavigationLink path={manage_team_path}>{team.name}</NavigationLink>
      <span className="text-muted">{team.description}</span>
    </div>
  );
}

/**
 * Formats a team member's avatar as an SVG element with their initials.
 *
 * @param {Object} member - The team member object.
 * @param {string} member.full_name - The full name of the team member.
 * @returns {JSX.Element} An SVG element representing the team member's avatar.
 */
function teamMemberAvatarFormatter(member) {
  const nameInitials = member.full_name
    .split(" ")
    .slice(0, 2)
    .map((word) => word[0]);
  return (
    <svg
      className="bd-placeholder-img rounded-circle"
      width="32"
      height="32"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      preserveAspectRatio="xMidYMid slice"
      focusable="false"
    >
      <rect width="100%" height="100%" fill="#868e96" />
      <text
        x="50%"
        y="50%"
        fill="#dee2e6"
        dy=".3em"
        textAnchor="middle"
        dominantBaseline="auto"
        className="fw-bold"
      >
        {nameInitials}
      </text>
    </svg>
  );
}

/**
 * Formats a list of team members into a series of avatar components.
 *
 * @param {Array} members - An array of team member objects.
 * @returns {JSX.Element} A JSX element containing the formatted avatars.
 */
function teamMembersAvatarFormatter(members) {
  return (
    <div className="d-flex gap-2">
      {members.map((member) => teamMemberAvatarFormatter(member))}
    </div>
  );
}

export const teamsColumns: Array = [
  columnHelper.accessor("name", {
    header: null,
    id: "name",
    cell: (info) => teamDisplayFormatter(info.row.original),
  }),
  columnHelper.accessor("members", {
    header: null,
    id: "members",
    cell: (info) => teamMembersAvatarFormatter(info.row.original.members),
  }),
  columnHelper.accessor("members", {
    header: null,
    id: "number",
    cell: function NumberCell(info) {
      return `${info.getValue().length} members`;
    },
  }),
];
