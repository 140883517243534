import { BaseModel } from "modules/base/models/Base";

/**
 * Represents a cluster location with its details.
 */
export class ClusterLocation extends BaseModel {
  id: number;

  name: string;

  country_name: string;

  country_code: string;

  clusters: any[];

  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.country_name = data.country_name;
    this.country_code = data.country_code;
    this.clusters = data.clusters;
  }
}

/**
 * Represents a Virtual Machine Template model with various properties.
 */
export class Template extends BaseModel {
  id: number;

  name: string;

  cpu: number;

  bandwidth: number;

  memory: number;

  plan_amount: number;

  plan_currency: string;

  plan_price_display: string;

  display_icon: string;

  display_icon_type: string;

  billing_plan: number;

  platform: string;


  constructor(data: any = {}) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.cpu = data.cpu;
    this.bandwidth = data.bandwidth;
    this.memory = data.memory;
    this.plan_amount = data.plan_amount;
    this.plan_currency = data.plan_currency;
    this.plan_price_display = data.plan_price_display;
    this.display_icon = data.display_icon;
    this.display_icon_type = data.display_icon_type;
    this.billing_plan = data.billing_plan;
    this.platform = data.platform;
  }
}
