import { Route, Routes } from "react-router-dom";
import { PATH_MANAGE_TEAM, PATH_TEAMS_LIST } from "modules/teams/Constants";
import ManageTeam from "modules/teams/layouts/ManageTeam";
import Teams from "modules/teams/layouts/Teams";

/**
 * Teams routes
 * @returns {JSX.Element}
 * @constructor
 */
function TeamsRoutes() {
  return (
    <Routes>
      <Route path={PATH_TEAMS_LIST} element={<Teams />} />
      <Route path={PATH_MANAGE_TEAM} element={<ManageTeam />} />
    </Routes>
  );
}

export default TeamsRoutes;
