import PropTypes from "prop-types";
import React, { useCallback } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ContentCard from "../../core/layouts/ContentCard";
import ServersApiActions from "../ApiActions";
import DisplayIcon from "modules/base/components/DisplayIcon";
import SelectOptionCard from "modules/base/components/inputs/SelectOptionCard";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import { showAlert } from "modules/base/utilities/Actions";
import { VirtualMachineLocation } from "modules/servers/Models";

/**
 * Operating system select component
 * @returns {JSX.Element}
 * @constructor
 */
function OperatingSystemSelect(props) {
  const {
    onSelect,
    selected_location,
    no_of_md_cols,
    selected_image,
    showConfirm,
    confirm,
    selectedPlatform,
  } = props;
  const cluster_ids = selected_location?.clusters;
  const serversApiActions = new ServersApiActions();
  const { images, error, isLoading } =
    serversApiActions.getVirtualMachineImages(selectedPlatform, cluster_ids);
  const [selectedImageId, setSelectedImageId] = React.useState(selected_image);
  const ConfirmSwal = withReactContent(Swal);
  const getOnSelect = useCallback((e) => {
    if (!showConfirm) {
      setSelectedImageId(Number(e.value));
      onSelect(e);
      return;
    }

    const previous_image_id = selectedImageId;
    showAlert(
      confirm,
      ConfirmSwal,
      () => {
        setSelectedImageId(Number(e.value));
      },
      (successCallback, errorCallback) => {
        setSelectedImageId(Number(e.value));
        onSelect(
          e,
          () => {
            successCallback();
          },
          (error_title, error_message) => {
            errorCallback(error_title, error_message);
            setSelectedImageId(previous_image_id);
          },
        );
      },
      () => {
        setSelectedImageId(previous_image_id);
      },
    );
  });
  const options = images.map((image) => {
    const icon_name = image.os_type?.toLowerCase();
    return (
      <SelectOptionCard
        key={image.id}
        value={image.id}
        label={image.name}
        subtitle={image.os_version}
        icon={<DisplayIcon icon_name={icon_name} icon_type="svg" size={25} />}
        section_name="image"
        onSelect={getOnSelect}
        isSelected={selectedImageId === image.id}
      />
    );
  });
  if (isLoading) {
    return <NetworkActivityIndicator />;
  }
  return (
    <>
      <NetworkMessageDisplay error={error} />
      <ContentCard
        header={
          <span>
            <i className="bi bi-disc text-purple" /> Operating system
          </span>
        }
      >
        <div
          className={`row row-cols-md-${no_of_md_cols} row-cols-1 gy-md-3 gy-1 mt-2`}
        >
          {options}
        </div>
      </ContentCard>
    </>
  );
}

OperatingSystemSelect.defaultProps = {
  selected_location: null,
  no_of_md_cols: 4,
  selected_image: null,
  showConfirm: false,
  confirm: {
    title: "Are you sure?",
    message: "You won't be able to revert this!",
    confirm_button_label: "Yes, do it!",
    action_done_title: "Done",
    action_done_message: "Your request has been processed successfully",
    confirm_button_color: "danger",
  },
};

OperatingSystemSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected_location: PropTypes.instanceOf(VirtualMachineLocation),
  no_of_md_cols: PropTypes.number,
  selected_image: PropTypes.number,
  showConfirm: PropTypes.bool,
  selectedPlatform: PropTypes.string.isRequired,
  confirm: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirm_button_label: PropTypes.string.isRequired,
    action_done_title: PropTypes.string.isRequired,
    action_done_message: PropTypes.string.isRequired,
    confirm_button_color: PropTypes.string,
  }),
};

export default OperatingSystemSelect;
