import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import Form from "modules/base/components/Form";
import SelectInput from "modules/base/components/inputs/SelectInput";
import TextInput from "modules/base/components/inputs/TextInput";
import ServersApiActions from "modules/servers/ApiActions";

/**
 * Form Column
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function FormColumn({ children }) {
  return <div className="col-md-6 col-lg-4">{children}</div>;
}

FormColumn.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Filter Form Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function FilterForm({ filters, handleFiltersChange }) {
  const serversApiActions = new ServersApiActions();

  const [filterOptions, setFilterOptions] = useState({
    state: filters.state,
    location: filters.location,
    os_type: filters.os_type,
    name: filters.name,
    ipv4: filters.ipv4,
    backup_template: filters.backup_template,
    plan: filters.plan,
  });

  const { locations } = serversApiActions.getVirtualMachineLocations();
  const { templates: backups } =
    serversApiActions.getVirtualMachineBackupTemplates();
  const { templates: plans } = serversApiActions.getVirtualMachineTemplates();
  const { images } = serversApiActions.getVirtualMachineImages();

  const handleFiltersSubmit = useCallback((e) => {
    e.preventDefault();
    const filterValues = Object.fromEntries(
      Object.entries(filterOptions).filter(
        ([_, value]) => value !== "" && value !== undefined,
      ),
    );
    handleFiltersChange(filterValues);
  });

  const handleInputChange = useCallback(
    (name, value) => {
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        [name]: value,
      }));
    },
    [filterOptions, setFilterOptions],
  );

  const handleTextInputChange = useCallback(
    (e) => {
      handleInputChange(e.target.name, e.target.value);
    },
    [filterOptions, setFilterOptions],
  );

  const handleSelectChange = useCallback(
    (name) => (selected) => {
      handleInputChange(name, selected.value);
    },
    [handleInputChange],
  );

  const createPredicate = useCallback(
    (field) => (option) => option.value === filterOptions[field],
    [filterOptions],
  );

  const backupPredicate = useMemo(
    () => createPredicate("backup_template"),
    [createPredicate],
  );
  const statePredicate = useMemo(
    () => createPredicate("state"),
    [createPredicate],
  );
  const locationPredicate = useMemo(
    () => createPredicate("location"),
    [createPredicate],
  );
  const osPredicate = useMemo(
    () => createPredicate("os_type"),
    [createPredicate],
  );
  const planPredicate = useMemo(
    () => createPredicate("plan"),
    [createPredicate],
  );

  const stateOptions = [
    { value: "", label: "--States--" },
    { value: "running", label: "Running" },
    { value: "stopped", label: "Stopped" },
    { value: "deleted", label: "Deleted" },
    { value: "paused", label: "Paused" },
    { value: "off", label: "Off" },
    { value: "stopping", label: "Stopping" },
    { value: "starting", label: "Starting" },
    { value: "errorunschedulable", label: "Progressing" },
    { value: "terminating", label: "Terminating" },
    { value: "creating", label: "Creating" },
    { value: "waitingforvolumebinding", label: "Attaching Disk" },
  ];

  const locationOptions = [
    { value: "", label: "--Locations--" },
    ...(locations
      ? locations.map((location) => ({
          value: location.id,
          label: location.name,
        }))
      : []),
  ];

  const backupOptions = [
    { value: "", label: "--Backup Types--" },
    ...(backups
      ? backups.map((backup) => ({ value: backup.id, label: backup.name }))
      : []),
  ];

  const osOptions = [
    { value: "", label: "--OS--" },
    ...(images
      ? images.map((image) => ({ value: image.id, label: image.name }))
      : []),
  ];

  const planOptions = [
    { value: "", label: "--Plans--" },
    ...(plans
      ? plans.map((plan) => ({ value: plan.id, label: plan.name }))
      : []),
  ];

  return (
    <Form
      handleSubmit={handleFiltersSubmit}
      id="Advance-filter-form"
      button_label="Filter"
      items_flow="vertical"
    >
      <div className="row mb-3">
        <FormColumn>
          <TextInput
            title=""
            inputType="text"
            name="display_name"
            controlFunc={handleTextInputChange}
            content={filterOptions.display_name}
            placeholder="Custom Name"
            className="form-control"
          />
        </FormColumn>
        <FormColumn>
          <TextInput
            title=""
            inputType="text"
            name="name"
            controlFunc={handleTextInputChange}
            content={filterOptions.name}
            placeholder="Hostname"
            className="form-control"
          />
        </FormColumn>
        <FormColumn>
          <TextInput
            title=""
            inputType="text"
            name="ipv4"
            controlFunc={handleTextInputChange}
            content={filterOptions.ipv4}
            placeholder="IP address"
            className="form-control"
          />
        </FormColumn>
        <FormColumn>
          <SelectInput
            name="backup_template"
            title=""
            options={backupOptions}
            onChange={handleSelectChange("backup_template")}
            predicate={backupPredicate}
            placeholder="Backup Plan"
          />
        </FormColumn>
        <FormColumn>
          <SelectInput
            name="state"
            title=""
            options={stateOptions}
            onChange={handleSelectChange("state")}
            predicate={statePredicate}
            placeholder="VM State"
          />
        </FormColumn>
        <FormColumn>
          <SelectInput
            name="location"
            title=""
            options={locationOptions}
            onChange={handleSelectChange("location")}
            predicate={locationPredicate}
            placeholder="Location"
          />
        </FormColumn>
        <FormColumn>
          <SelectInput
            name="os_type"
            title=""
            options={osOptions}
            onChange={handleSelectChange("os_type")}
            predicate={osPredicate}
            placeholder="OS type"
          />
        </FormColumn>
        <FormColumn>
          <SelectInput
            name="plan"
            title=""
            options={planOptions}
            onChange={handleSelectChange("template")}
            predicate={planPredicate}
            placeholder="Plan"
          />
        </FormColumn>
      </div>
    </Form>
  );
}

FilterForm.defaultProps = {
  filters: {
    name: "",
    display_name: "",
    ipv4: "",
    backup_template: "",
    state: "",
    location: "",
    os_type: "",
    plan: "",
  },
  handleFiltersChange: null,
};

FilterForm.propTypes = {
  filters: PropTypes.shape({
    name: PropTypes.string,
    display_name: PropTypes.string,
    ipv4: PropTypes.string,
    backup_template: PropTypes.string,
    state: PropTypes.string,
    location: PropTypes.string,
    os_type: PropTypes.string,
    plan: PropTypes.string,
  }),
  handleFiltersChange: PropTypes.func,
};

export default FilterForm;
