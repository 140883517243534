import { useParams } from "react-router-dom";
import NetworkActivityIndicator from "modules/base/components/NetworkActivityIndicator";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import SectionCard from "modules/base/components/SectionCard";
import TeamsApiActions from "modules/teams/ApiActions";
import InviteMemberForm from "modules/teams/components/InviteMemberForm";
import TeamDescription from "modules/teams/components/TeamDescription";
import TeamMembersList from "modules/teams/components/TeamMembersList";

/**
 * Team's information display component
 * @returns {JSX.Element}
 * @component
 */
function Team() {
  const { id: team_id } = useParams();
  const teamsApiActions = new TeamsApiActions();
  const { team, isLoading, error } = teamsApiActions.getTeam(team_id);

  if (isLoading) {
    return <NetworkActivityIndicator />;
  }

  if (error) {
    return <NetworkMessageDisplay error={error} />;
  }

  return (
    <div>
      <InviteMemberForm teamId={team_id} />
      <TeamDescription team={team} />
      <SectionCard title="Members">
        <TeamMembersList members={team.members} isLoading={isLoading} />
      </SectionCard>
    </div>
  );
}

export default Team;
