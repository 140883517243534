import ContentContainer from "modules/core/layouts/ContentContainer";
import MainContainer from "modules/core/layouts/MainContainer";
import SideBar from "modules/core/layouts/SideBar";
import TopBar from "modules/core/layouts/TopBar";
import Team from "modules/teams/components/Team";

/**
 * Team details component renders the layout for the teams list page.
 *
 * @component
 * @returns {JSX.Element} The rendered Teams component.
 */
function ManageTeam() {
  return (
    <>
      <TopBar />
      <MainContainer>
        <SideBar />
        <ContentContainer cols={10} mobile_cols={10}>
          <Team />
        </ContentContainer>
      </MainContainer>
    </>
  );
}

export default ManageTeam;
