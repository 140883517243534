import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthProvider, RequireAuth } from "./AuthProvider";
import BillingRoutes from "./modules/billing/Router";
import DashboardRoutes from "./modules/dashboard/Router";
import ServerRoutes from "./modules/servers/Router";
import { PATH_ACCOUNT } from "modules/account/Constants";
import AccountRoutes from "modules/account/Router";
import { PATH_APPS } from "modules/apps/Contants";
import AppsRoutes from "modules/apps/Router";
import { PATH_BILLING } from "modules/billing/Contants";
import { PATH_FEEDBACK, PATH_MAIN_ROUTE } from "modules/core/Constants";
import RequestFeature from "modules/core/layouts/RequestFeature";
import {
  FULL_PATH_LIST_VIRTUAL_MACHINES,
  PATH_VIRTUAL_MACHINES,
} from "modules/servers/Constants";
import { PATH_TEAMS } from "modules/teams/Constants";
import TeamsRoutes from "modules/teams/Router";

/**
 * Main application router
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={FULL_PATH_LIST_VIRTUAL_MACHINES} replace />}
          />
          <Route path={`${PATH_ACCOUNT}/*`} element={<AccountRoutes />} />
          <Route path={`${PATH_MAIN_ROUTE}/*`} element={<DashboardRoutes />} />
          <Route element={<RequireAuth />}>
            <Route
              path={`${PATH_VIRTUAL_MACHINES}/*`}
              element={<ServerRoutes />}
            />
            <Route path={`${PATH_BILLING}/*`} element={<BillingRoutes />} />
            <Route path={`${PATH_APPS}/*`} element={<AppsRoutes />} />
            <Route path={`${PATH_FEEDBACK}/*`} element={<RequestFeature />} />
            <Route path={`${PATH_TEAMS}/*`} element={<TeamsRoutes />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
