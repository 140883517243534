import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import Modal from "modules/base/components/Modal";
import NetworkMessageDisplay from "modules/base/components/NetworkMessageDisplay";
import ServersApiActions from "modules/servers/ApiActions";
import BackupPlanSelect from "modules/servers/components/BackupPlanSelect";

/**
 * BackupSubscription component allows users to change the backup frequency and plan for a virtual machine.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.vm_id - The ID of the virtual machine.
 * @param {string} props.vm_type - The type of the virtual machine.
 * @returns {JSX.Element} The rendered BackupSubscription component.
 */
function BackupSubscription(props) {
  const serversApiActions = new ServersApiActions();
  const { vm_id, vm_type } = props;
  const { vm } = serversApiActions.getVirtualMachine(vm_id, vm_type);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const handleDisableBackup = useCallback(
    (enabled, successCallback, errorCallback) => {
      if (!enabled) {
        serversApiActions.disableVirtualMachineBackups(
          vm_id,
          () => {
            successCallback();
            setSuccess("Backup has been disabled");
          },
          (error_response) => {
            errorCallback();
            setError(error_response);
          },
        );
      }
    },
  );
  const handleChangeBackup = useCallback(
    (e, _billing_plan_id, successCallback, errorCallback) => {
      const template_id = Number(e.value);
      serversApiActions.changeVirtualMachineBackupTemplate(
        vm_id,
        template_id,
        () => {
          setSuccess({ message: "Backup plan has been changed" });
          successCallback();
        },
        (error_response) => {
          setError(error_response);
          errorCallback("Error upgrading plan", error_response.message);
        },
      );
    },
  );
  return (
    <Modal
      modal_title="Change backup frequency"
      modal_id="change-backup-frequency"
      size="md"
      isDismissible
    >
      <NetworkMessageDisplay error={error} success={success} />
      <BackupPlanSelect
        selected_vm_template_id={vm.template}
        no_of_md_cols={2}
        selected_template_id={vm.backup_template}
        showCardContainer={false}
        showDisableConfirm
        onEnableDisable={handleDisableBackup}
        onPlanSelect={handleChangeBackup}
        showConfirm
        confirm={{
          title: "Change backup plan?",
          message:
            "This action will change the backup billing plan for this resource. Are you sure you want to continue?",
          confirm_button_label: "Change backup plan",
          action_done_title: "Backup plan changed",
          action_done_message: "The backup plan has been changed.",
          confirm_button_color: "success",
        }}
      />
      <div className="fw-lighter fs-7 mt-3">
        * Disabling or downgrading backup will take effect from next billing
        cycle
      </div>
    </Modal>
  );
}

BackupSubscription.propTypes = {
  vm_id: PropTypes.string.isRequired,
  vm_type: PropTypes.string.isRequired,
};

export default BackupSubscription;
